import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import Contact from "../contact";

const Info = () => {
  return (
    <div className="py-8 bg-gray-200">
      <div className="container mx-auto px-2 md:px-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 gap-y-8">
          <StaticImage className="rounded-md shadow-lg" src="../../images/huette.png" alt="hütte"></StaticImage>
          <div className="col-span-2 md:ml-4">
            <div className="">
              <h3>Öffnungszeiten</h3>
              <ul className="list-disc list-inside">
                <li>Ende April bis Ende Oktober</li>
                <li>Samstags und Sonntags bewartet</li>
              </ul>
            </div>
            <div className="">
              <h3>Kontakt</h3>
              <ul className="list-disc list-inside">
                <li>
                  <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon> <Link to="angebot/uebernachten#reservation">Reservationen</Link>
                </li>
              </ul>
              <Contact></Contact>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Info;
