import { faBed, faBeer, faHiking, faMountain } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import * as React from "react";

const Offering = ({ children }: any) => {
  const classForCard = "rounded border border-gray-50 max-w-sm justify-self-center p-2 shadow-lg";
  const classForLink = "text-blue-900 hover:text-sky-700 hover:no-underline";
  const classForHeader = "justify-self-center text-center";
  const classForTitle = "text-center text-2xl my-4";
  const classForText = "";

  return (
    <div className="my-8">
      <div className="container mx-auto px-2 md:px-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 gap-y-8">
          <div className={classForCard}>
            <Link to="/angebot/wirtschaft" className={classForLink}>
              <div className={classForHeader}>
                <FontAwesomeIcon icon={faBeer} size="5x" />
              </div>
              <div className={classForTitle}>Wirtschaft</div>
            </Link>
            <div className={classForText}>
              Wir bieten <Link to="/angebot/wirtschaft#getraenke">Getränke</Link> für den Durst und{" "}
              <Link to="/angebot/wirtschaft#kleine-imbisse">kleine Imbisse</Link> für den Hunger.
            </div>
          </div>
          <div className={classForCard}>
            <Link to="/angebot/uebernachten" className={classForLink}>
              <div className={classForHeader}>
                <FontAwesomeIcon icon={faBed} size="5x" />
              </div>
              <div className={classForTitle}>Übernachten</div>
            </Link>
            <div className={classForText}>
              Die Hütte verfügt über einen kleinen Schlafraum für Gäste, um vom Samstag auf den Sonntag zu übernachten.
              <div className="my-4 justify-self-center text-center">
                <Link className="link-btn block" to="angebot/uebernachten#reservation">
                  Reservationen
                </Link>
              </div>
            </div>
          </div>
          <div className={classForCard}>
            <Link to="/angebot/klettern" className={classForLink}>
              <div className={classForHeader}>
                <FontAwesomeIcon icon={faMountain} size="5x" />
              </div>
              <div className={classForTitle}>Klettern</div>
            </Link>
            <div className={classForText}>
              Das Spektrum reicht von Genusstouren wie die Namenlose Kante bis zu alpinen Klassikern, wie die Direkte Nordwand.
            </div>
          </div>
          <div className={classForCard}>
            <Link to="/angebot/wandern" className={classForLink}>
              <div className={classForHeader}>
                <FontAwesomeIcon icon={faHiking} size="5x" />
              </div>
              <div className={classForTitle}>Wandern</div>
            </Link>
            <div className={classForText}>Die Bockmattlihütte liegt inmitten von wunderbaren Wanderungen und Bergtouren.</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Offering;
