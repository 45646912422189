import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

const Chronicle = () => {
  const classForLinkCard =
    "flex items-center bg-white rounded-lg border shadow-md flex-row max-w-3xl hover:bg-gray-100 hover:no-underline dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700";

  return (
    <div className="py-8 bg-gray-200">
      <div className="container mx-auto px-4 md:px-8">
        <div className="flex justify-center mb-4">
          <h3>Chronik</h3>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 gap-y-8 mb-8 justify-center">
          <Link to="/chronik/klettern" className={classForLinkCard}>
            <StaticImage className="rounded-md shadow-lg" src="../../images/chronicle/klettern.jpg" alt="klettern" height={80}></StaticImage>
            <div className="flex flex-col justify-between p-4 leading-normal">
              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Klettern</h5>
            </div>
          </Link>
          <Link to="/chronik/huette" className={classForLinkCard}>
            <StaticImage className="rounded-md shadow-lg" src="../../images/chronicle/huette.jpg" alt="huette" height={80}></StaticImage>
            <div className="flex flex-col justify-between p-4 leading-normal">
              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Hütte</h5>
            </div>
          </Link>
          <Link to="/chronik/huettengemeinschaft" className={classForLinkCard}>
            <StaticImage
              className="rounded-md shadow-lg"
              src="../../images/chronicle/huettengemeinschaft.jpg"
              alt="huettengemeinschaft"
              height={80}
            ></StaticImage>
            <div className="flex flex-col justify-between p-4 leading-normal">
              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Hüttengemeinschaft</h5>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default Chronicle;
