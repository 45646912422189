import * as React from "react";
import Layout from "../components/layout";
import Offering from "../components/landing/offering";
import Info from "../components/landing/info";
import Hero from "../components/landing/hero";
import Access from "../components/landing/access";
import Chronicle from "../components/landing/chronicle";
import Impressions from "../components/landing/impressions";

const IndexPage = () => {
  return (
    <Layout pageTitle="Bockmattli">
      <Hero></Hero>
      <Offering></Offering>
      <Info></Info>
      <Access></Access>
      <Chronicle></Chronicle>
      <Impressions></Impressions>
    </Layout>
  );
};

export default IndexPage;
