import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";

const Info = ({ children }: any) => {
  return (
    <div className="h-40 md:h-80 bockli-hero-img text-center text-3xl md:text-3xl flex justify-center pb-4">
      <FontAwesomeIcon className="text-white self-end " icon={faAngleDown} />
    </div>
  );
};
export default Info;
