import { faBus, faCar, faRoute } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";

const Access = ({ children }: any) => {
  return (
    <div className="py-8 bg-white">
      <div className="container mx-auto px-2 md:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 gap-y-8">
          <div>
            <h3>Zustieg</h3>
            <p className="mt-1 mb-2">
              <FontAwesomeIcon icon={faRoute} style={{ color: "#0078ff" }}></FontAwesomeIcon> Via Strasse Schwarzenegg{" "}
              <a style={{ color: "#0078ff" }} href="/tracks/bockmattliviaschwarzeneggstrasse.gpx" target="_blank">
                GPX
              </a>
              <br />
              <FontAwesomeIcon icon={faRoute} style={{ color: "#ff0014" }}></FontAwesomeIcon> Via Fällätschen{" "}
              <a style={{ color: "#ff0014" }} href="/tracks/bockmattliviafallatschen.gpx" target="_blank">
                GPX
              </a>
            </p>
            <iframe
              title="SwisstopoMap"
              width="100%"
              height="400px"
              src="https://map.geo.admin.ch/embed.html?lang=de&topic=ech&bgLayer=ch.swisstopo.pixelkarte-farbe&layers=KML%7C%7Chttps%3A%2F%2Fgist.githubusercontent.com%2Fbittailor%2F99d3fa6d25c0799f5c4c7d36344bf78f%2Fraw%2Febc842cf43d88306ff85681e5a8eb02af2b26aaa%2Fbockmattliviaschwarzeneggstrasse.kml,KML%7C%7Chttps%3A%2F%2Fgist.githubusercontent.com%2Fbittailor%2F99d3fa6d25c0799f5c4c7d36344bf78f%2Fraw%2F815f5017f292e4d2b4f86fc7e3bcf5ba79b4efc0%2Fbockmattliviafallatschen.kml,ch.bav.haltestellen-oev&layers_opacity=1&layers_visibility=true&E=2713705.84&N=1217754.69&zoom=6.2"
              frameBorder={0}
              style={{ border: 0 }}
              className="shadow-lg rounded-md"
            ></iframe>
          </div>
          <div>
            <h3>Anreise</h3>
            <p className="mt-1 mb-2">
              <FontAwesomeIcon icon={faBus}></FontAwesomeIcon> Innerthal Post{" "}
              <a href="https://www.sbb.ch/de/kaufen/pages/fahrplan/fahrplan.xhtml?nach=Innerthal%2C%20Post" target="_blank" rel="noreferrer">
                {" "}
                SBB Fahrplan
              </a>
              <br />
              <FontAwesomeIcon icon={faCar}></FontAwesomeIcon> Ennenda, Innerthal{" "}
              <a href="https://www.google.com/maps/dir//47.09335,8.93137/@47.09335,8.93137,10z?hl=de-CH" target="_blank" rel="noreferrer">
                {" "}
                Routenplanner
              </a>
            </p>
            <iframe
              title="googleMaps"
              width="100%"
              height="400px"
              src="https://www.google.com/maps/embed/v1/place?key=AIzaSyARGcxFczTPkyFCwggUnE-ijFkzTn9IXbU&q=47.09335,8.93137&zoom=10&language=de-CH"
              frameBorder={0}
              style={{ border: 0 }}
              allowFullScreen={false}
              aria-hidden="false"
              tabIndex={0}
              className="shadow-lg rounded-md"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Access;
