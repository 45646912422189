import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

const Impressions = () => {
  return (
    <div className="py-8 bg-white">
      <div className="container mx-auto px-4 md:px-8 grid-cols-1 md:grid-cols-2 ">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 gap-y-8 items-center">
          <div className="">
            <h3>Impressionen</h3>
          </div>
          <div className="col-span-2">
            <Link to="/gallery">
              <StaticImage className="rounded-md shadow-lg" src="../../images/gallery.png" alt="hütte"></StaticImage>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Impressions;
